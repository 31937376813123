<template>
  <div id="expenses-landing" class="page-owners-associations-overview">

    <section id="nav" class="globalnav-desktop">
      <div class="container-fluid">
        <nav class="navbar navbar-dark">
            <router-link class="navbar-brand" to="/">
            <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
          </router-link>

        </nav>
      </div>
    </section>
    <header-back title=" VOLVER" :url="redirect()" />
    <div class="container">
      <div class="page-owners-associations-documents">

        <div class="container">
          <p class="section-header">Reglamentos</p>
        </div>
        <div v-if="isValidLink">
          <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
       
          <div>
          
           <div class="empty-state empty-state-documents-date" style="margin-top:5rem !important"
             v-if="!documents || documents.length === 0" id="no-documents-message">
              <div class="wrapper">
                <h4>No hay reglamentos</h4>

                <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
              </div>
            </div>

            <a v-for="doc in documents" :key="doc.id" :href="doc.url" class="news-item news-item-with-image" v-else
              style="padding: 1rem 1rem !important; margin-top: 3rem">
              <div class="news-main" style="align-items: center">
                <span class="document-icon-container circled shadow"><img src="@/assets/img/icons/document.svg"
                    class="document-icon" alt="" /></span>

                <div class="news-data" style="flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 85%;">

                  <h3 class="news-title">{{ doc.title | truncate(28) }}</h3>

                  <p class="news-meta">
                    <span class="news-date">{{ doc.created_at | moment('ll') }}</span>
                  </p>
                </div>
              </div>
            </a>

          </div>
        </div>
        <div v-else class="empty-state empty-state-documents-date">
          <div class="wrapper">
            <h4>No hay datos disponibles</h4>

            <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
          </div>
        </div>
      </div>

    </div>

    <footer id="footer" class="container-fluid footer">
      <span style="color:white"> Para ver mas datos de tu unidad funcional ingresa en </span>
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
      </router-link>
    </footer>
  </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import axios from 'axios';
import DropdownSelector from '@/components/Layouts/DropdownSelector';
import HeaderBack from '@/components/Layouts/HeaderBack';
import Settlements from '@/components/Settlements/Settlements';

export default {
  name: 'Reglamentos',
  components: {
    HeaderBack, VueBottomSheet,
  },
  props: [],
  data() {
    return {
      browserUrl: window.location,
      created_at: null,
      isLoading: false,
      delay_days: 15,
      documents: {},
      ownersAssociationId: null,
      isValidLink: true,
    };
  },
  computed: {
    owners_association() {
      if (this.$route.params.owners_association_id) {

        return { id: this.$route.params.owners_association_id };

      }
      return this.$store.getters.ownersAssociation;
    },

  },
  methods: {
    redirect() {

      return `/expenses/id/${this.userId}/ownersAssociation/${this.ownersAssociationId}`;
    },
    },
  async created() {
    this.isLoading = true;
    this.userId = localStorage.getItem('user_id');
    this.ownersAssociationId = localStorage.getItem('owners_association_id');
    if (this.ownersAssociationId) {
      // `${process.env.VUE_APP_CORE_SERVICE_URL}/owners_associations/${this.ownersAssociationId}/periods/${this.period.period_id}/documents`, {
   await axios.get(`${process.env.VUE_APP_CORE_SERVICE_URL}/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/documents`, {

        headers:
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',

        },
      }).then(async (res) => {
        console.log(JSON.stringify(res.data.data));
        this.documents = res.data.data;
        // this.period = await res.data.data.period;
        this.isLoading = false;
      }).catch(() => {
        this.isValidLink = false;
      });

      /*  await axios.get(
                `${process.env.VUE_APP_CORE_SERVICE_URL}/owners_associations/${this.$route.params.owners_association_id}/document_types/3/documents`, {
                  headers:
                          {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                },
        ).then(async (res) => {
console.log(JSON.stringify(res));
          this.isLoading = false;
        }).catch(() => {
        }); */
    }
  },

};
</script>

<style lang='scss'>
.header {
  min-height: 6em;
}

.header-text {
  font-weight: 300;
  letter-spacing: 0.075em;
  padding: 1em 0;
}

.owners-associations-card {
  padding: .5em 0;
  border-radius: 6px;
  margin-top: 1.5rem;
}

.owners-association-data {
  line-height: -1.25rem;
}

.settlement-card {
  padding: .5em 0;
  border-radius: 6px;
  margin-top: 1.5rem;
  margin-bottom: -5.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 12), 0 1px 2px rgba(0, 0, 0, 24);
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.settlement-month {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  color: #000;
  text-transform: uppercase;
  font-weight: 300;
}

.owners-association-city-image {
  max-width: 98%;
  height: auto;
  display: block;
  z-index: 998;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.settlement-overview {
  display: block;
  margin-top: -5rem;
  z-index: 999;
}

.settlement-data {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

#settlement-actions {
  padding: .75em 1.25em .75em 1.25em;
  justify-content: center;
}

#download_documents,
#download_settlements,
#view_invoices {
  padding-top: 0.75em !important;
  padding-bottom: 0 !important;
}

#footer-login-text {
  letter-spacing: .075em;
  margin-top: -3.5rem;
  font-weight: 300;
}

#footer-login-btn {
  margin-bottom: -3.5em;
}

#expenses-landing {
  background-color: white
}

.footer {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 30vh;
  padding: .5rem 1rem;
  justify-content: center;
  background-color: #081a2b;
  z-index: 9999 !important;
}

.logo {
  width: 100px;
  height: auto;
}

@media all and (max-width: 360px) {
  .btn {
    min-width: 1px !important;
  }
}

.document-item {
  margin-bottom: 0;
}

.documents-index .document-item .document-data {
  margin-top: 0 !important;
}

.documents-index .document-item .document-data .document-title {
  margin-top: 0 !important;
  font-weight: bold;
}

.let p {
  text-transform: lowercase;
  font-weight: bold;
  color: #858991;
}

.let p:first-letter {
  text-transform: uppercase;
}

.page-owners-associations-documents {
  padding-bottom: 5rem;
  min-height: 90vw;
}

.empty-state-documents-date {
  margin-top: 5rem !important;
}

@media (max-width: 770px) {
  .let p {
    margin-bottom: 2rem;
  }
}
</style>
