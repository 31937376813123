<template>
  <div class="page-owners-associations-documents">
   <div class="container">
      <p class="section-header">Últimos documentos</p>
    </div>
    <div v-if="is_capacitor && type!=null" class="container">
      <h2 class="section-header max_header" @click="open" >
        <span v-if="type.name!=null" class="font-weight-bold text-uppercase" style="color: #CE4169">{{ type.name }}<span
            class="dropdown-toggle ml-2"></span></span>
      </h2>
    </div>
    <dropdown-selector v-else @selected="changeType" :options="types" :default="type" />

    <div v-if="type!=null && type.slug === 'comprobantes'">
      <div class="container" v-if="is_capacitor && (periodsSelect !== null || period !== null)">
        <h2 class="section-header max_header" @click="openMobile">
          <div class="btn-group">
            <span class="font-weight-bold text-uppercase" style="color: #CE4169">{{ period.title }}<span
            class="dropdown-toggle ml-2"></span></span>
          </div>
        </h2>
      </div>
      <dropdown-selector v-else-if="!is_capacitor && (periodsSelect !== null || period !== null)"
        @selected="changePeriod" :options="periodsSelect" :default="period" />
    </div>
    <vue-bottom-sheet ref="myBottomSheet">
      <div v-for="type in types" :key="type.id" @click="changeType(type)">
        <hr>
        <span class="ml-3">{{ type.name }}</span>
      </div>
      <br>
    </vue-bottom-sheet>
    <vue-bottom-sheet ref="myBottomSheetMobile">
      <div v-for="period in periodsSelect" :key="period.id" @click="changePeriod(period)">
        <hr>
        <span class="ml-3">{{ period.title || '' }}</span>
      </div>
      <br>
    </vue-bottom-sheet>

    <div class="documents-index">
      <div class="container">

        <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />

        <div v-if="type!=null && type.slug === 'comprobantes' && daysLeft().show === true"
          class="empty-state empty-state-documents-date">
          <div class="wrapper">
            <h4>¡Aún no hay comprobantes!</h4>
            <p>Tus comprobantes estarán disponibles en {{ daysLeft().days }} dias</p>
            <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
          </div>
        </div>

        <div class="empty-state empty-state-documents" v-else-if="no_documents " id="no-documents-message">

          <div class="wrapper">
            <h4>¡Aún no hay documentos!</h4>
            <p>En esta sección vas a encontrar actas de asamblea, balances, reglamentos y otros documentos de interés.
            </p>
            <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">

          </div>
        </div>

        <a v-for="doc in documents" :key="doc.id" :href="doc.url" class="news-item news-item-with-image"
          v-else-if="!no_documents && !is_capacitor" style="padding: 1rem 1rem !important;">
          <div class="news-main" style="align-items: center">
            <span class="document-icon-container circled shadow"><img src="@/assets/img/icons/document.svg"
                class="document-icon" alt="" /></span>

            <div class="news-data" style="flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 85%;">

              <h3 class="news-title">{{ doc.title | truncate(28) }}</h3>
              <div v-if="doc.type !=null" class="news-body body_news">{{ doc.type}}</div>
              <p class="news-meta">
                <span class="news-date">{{ doc.created_at | moment('ll') }}</span>
              </p>
            </div>
          </div>
        </a>
        <a v-for="doc in documents" :key="doc.id" @click="downloadFallback(doc)" class="news-item news-item-with-image "
          v-else-if="!no_documents && is_capacitor" style="padding: 1rem 1rem !important;">
          <div class="news-main" style="align-items: center">
            <span class="document-icon-container circled shadow"><img src="@/assets/img/icons/document.svg"
                class="document-icon" alt="" /></span>

            <div class="news-data" style="flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 85%;">

              <h3 class="news-title" style="font-size: 0.7rem; font-weight: 700">{{ doc.title | truncate(28) }}</h3>
              <div v-if="doc.type !=null" class="news-body body_news">{{ doc.type}}</div>
              <p class="news-meta">
                <span class="news-date">{{ doc.created_at | moment('ll') }}</span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>

  </div>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Dialog } from '@capacitor/dialog';
import axios from 'axios';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import DropdownSelector from '@/components/Layouts/DropdownSelector';
import OwnersAssociationHeader from '@/components/Layouts/OwnersAssociationHeader';

export default {
  name: 'Documents',
  components:
  {
    OwnersAssociationHeader,
    DropdownSelector,
    VueBottomSheet,
  },
  props: ['owners_association'],
  data() {
    return {
      documents: [],
      types: [],
      type: null,
      isLoading: false,
      pdf: null,
      title: null,
      localuri: null,
      doc_type: this.$route.query.docType,
      periodsSelect: [],
      period: null,
      settlement_created_at: null,
    };
  },
  computed: {
    no_documents() {
      return !this.isLoading && (!this.types || !this.documents || this.documents?.length === 0);
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
  methods:
  {
    open() {
      this.$refs.myBottomSheet.open();
    },
    openMobile() {
      this.$refs.myBottomSheetMobile.open();
    },
    close() {
      this.$refs.myBottomSheet.close();
    },
    closeMobile() {
      this.$refs.myBottomSheetMobile.close();
    },
    daysLeft() {
      // Obtener la fecha actual
      const fechaActual = new Date();
      console.log('created');

      // eslint-disable-next-line no-restricted-globals
      if (!this.period.settlement_created_at || isNaN(this.invoices_delay)) return { show: false, days: 0 };

      // Convertir la fecha proporcionada a un objeto Date
      const fechaComparar = new Date(this.period.settlement_created_at);

      // Sumar la cantidad de días a la fecha dada

      console.log(fechaComparar);
      console.log(this.invoices_delay);
      // eslint-disable-next-line radix
      fechaComparar.setDate(fechaComparar.getDate() + parseInt(this.invoices_delay));
      console.log(fechaComparar);

      // Calcular la diferencia en días
      const diferenciaMs = Math.abs(fechaActual - fechaComparar);
      const diferenciaDias = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

      // Verificar si la fecha resultante es menor o igual a la fecha actual
      if (fechaComparar <= fechaActual) {
        return { show: false, days: diferenciaDias };
      }
      return { show: true, days: diferenciaDias };

    },

    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async () => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async () => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
          .then(async (result) => {
            if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
              return Filesystem.requestPermissions()
                .then(async (publicStorage) => {
                  const permissionsRequested = await publicStorage.publicStorage;
                  console.log('prompt || denied || prompt-with-rationale');
                  return `${permissionsRequested}` === 'granted';
                });
            }
            return true;
          });
      }
      return true;
    },
    async downloadFallback(doc) {
      this.loading = true;
      this.pdf = null;
      console.log(`documento:${doc.url}`);
      const type = doc.url.split('.').pop();
      console.log(`es de tipo ${type}`);
      if (type.toLowerCase() != 'pdf' && type.toLowerCase() != 'jpeg' && type.toLowerCase() != 'png' && type.toLowerCase() != 'jpg') {
        console.log('paso');
        if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
          console.log('opening window in android');
          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android and web will just grant without prompting
          const browser = InAppBrowser.create(doc.url, '_blank', 'hidden=no,location=no,clearsessioncache=yes,clearcache=yes');
          browser.on('loadstart').subscribe(e => {
            console.log(e.url);
            if (e.url.includes('joinup')) {
              browser.close();
            }
          });
          this.isLoading = false;
          //
        }
      } else {
        await this.hasFilePermisions().then(() => {

          axios.get(doc.url, {
            responseType: 'arraybuffer',
          }).then(async (success) => {
            this.hasFilePermisions().then(async (resultData) => {

              // if (resultData) {
              const arrayUrl = doc.url.split('/');
              const fileName = arrayUrl[arrayUrl.length - 1];
              const pdfData = await success.data;
              await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
              this.pdf = doc.url;
              await this.$router.push({
                name: 'pdf-view',
                params: { url: this.pdf, localuri: this.localuri },
              });
              // } 
              /* else {
                await Dialog.alert({
                  title: 'Información',
                  message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
                });
              } */
            });
          });
        });
      }
    },

    getDocumentTypes() {
      return this.owners_association.document_types?.filter(
        (t) => t.name !== 'Avisos de pago' && t.name !== 'Liquidaciones de gastos',
      ).sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },

    changeType(type) {
      this.isLoading = true;
console.log(`type:${type}`);
console.log(`type:${type.slug}`);
      if (!type) {
        this.isLoading = false;
        return;
      }

      this.getDocumentsAlt(this.owners_association.id, type?.slug)
        .then((documents) => {
          console.log('3');
          this.documents = documents.documents;
          this.periodsSelect = documents.periods;
          this.type = type;
          if (type.slug === 'comprobantes') {
            this.loadPeriodsSelect();
          }
          this.isLoading = false;
          this.close();
          this.closeMobile();
        })
        .catch(() => {
          this.isLoading = false;
          this.close();
          this.closeMobile();
        });
      
    },
    changePeriod(period) {
      this.isLoading = true;

      if (!period) {
        this.isLoading = false;
        return;
      }
      this.period = period;
      console.log('change');
      console.log(JSON.stringify(this.period));
      this.getDocumentsAlt(this.owners_association.id, this.type?.slug)
        .then((documents) => {
          console.log(JSON.stringify(documents.documents));
          this.documents = documents.documents?.filter((doc) => doc.period_id === period.id);

          this.isLoading = false;
          this.closeMobile();
        })
        .catch(() => {
          this.isLoading = false;

          this.closeMobile();
        });
      this.isLoading = false;
    },

    loadPeriodsSelect() {
      console.log('loadPeriods');

      this.invoices_delay = this.owners_association.administration.invoices_delay;
      // eslint-disable-next-line prefer-destructuring
      if (this.periodsSelect?.length > 0) {
        [this.period] = this.periodsSelect;
        this.settlement_created_at = this.period.settlement_created_at;

        this.changePeriod(this.period);
      }

    },
  },

  created() {
    if (this.owners_association.document_types) {
      this.types = this.getDocumentTypes();
      [this.type] = this.types;
    }
    const docTypeExist = this.getDocumentTypes().find(type => this.$route.query.docType == type?.slug);
    console.log(docTypeExist);
   
    if (docTypeExist) {
      this.changeType(docTypeExist);
    } else {
     console.log('a');
      if (this.type != null) this.changeType(this.type);
    }

  },
};
</script>

<style lang='scss'>
.bottom-sheet__content {
  height: 20rem !important;
}
</style>
